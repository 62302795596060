import { AxiosResponse } from "axios";
import { ApiBrave } from "../../Apis/Brave";
import {
  IFaturasRelatorioRelacionamentoProps,
  IPageFaturaRelacionadaDTO,
} from "../../types/fatura";

const basePath = "/faturas";

export class Faturas {
  static async relatorio_relacionamento(
    props: IFaturasRelatorioRelacionamentoProps
  ): Promise<AxiosResponse<IPageFaturaRelacionadaDTO>> {
    const params = new URLSearchParams(Object.entries(props)).toString();
    return ApiBrave.get(`${basePath}/relatorio-relacionamento?${params}`);
  }
}
