import { useMediaQuery } from "react-responsive";
import { IPagination } from "../../../types/pagination";
import { FormEvent, useEffect, useState } from "react";
import { v4 } from "uuid";
import { ISelectOptions } from "../../../types/inputs";
import { MesesDoAnoEnum } from "../../../enums/mesesEnum";
import { useLocation } from "react-router-dom";
import { Faturas } from "../../../services/Faturas";
import { useContextSite } from "../../../context/Context";
import { toast } from "react-toastify";
import {
  IFaturaRelacionadaDTO,
  IFaturasRelatorioRelacionamentoProps,
} from "../../../types/fatura";

type GetRelatoriosProps = Omit<IFaturasRelatorioRelacionamentoProps, "empresa">;
export const useBankReconciliationReport = () => {
  const { pathname } = useLocation();
  const { setIsLoad } = useContextSite();
  const empresa = pathname.split("/").pop();
  const isMobile = useMediaQuery({ maxWidth: "640" });
  const [pagination, setPagination] = useState<IPagination>({} as IPagination);
  const [numberPage, setNumberPage] = useState(0);
  const currentYear = new Date().getFullYear();
  const currentMoth = new Date().getMonth() + 1;
  const yearOptions = Array.from({ length: currentYear - 2019 }, (_, index) => {
    const year = 2020 + index;
    return { value: year, label: year.toString() };
  });
  const mothOptions: ISelectOptions[] = Object.entries(MesesDoAnoEnum)
    .filter((i) => !isNaN(parseInt(i[0])))
    .map((v) => ({
      value: v[0],
      label: v[1] as string,
    }));
  const [filter, setFilter] = useState({
    ano: currentYear,
    mes: currentMoth,
  });
  const [faturasRelacionadas, setFaturasRelacionadas] = useState<
    IFaturaRelacionadaDTO[]
  >([]);

  const getRelacionamento = ({ ano, mes, ...rest }: GetRelatoriosProps) => {
    setIsLoad(true);

    if (!empresa) return;

    Faturas.relatorio_relacionamento({
      empresa,
      ano,
      mes,
      ...rest,
      size: 10,
    })
      .then(({ data }) => {
        setFaturasRelacionadas(data?.content);

        setPagination({
          actualPage: data.number,
          totalPage: data.totalPages,
          totalRegister: data.totalElements,
        });
      })
      .catch(
        ({
          response: {
            data: { mensagem },
          },
        }) => toast.error(mensagem)
      )
      .finally(() => {
        setIsLoad(false);
      });
  };

  function handleFilter(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (numberPage > 0) {
      return setNumberPage(0);
    }
    getRelacionamento({ ...filter });
  }

  async function getDoc() {
    setIsLoad(true);
    const token = sessionStorage.getItem("@token");
    let path = `${process.env.REACT_APP_BRAVE_API_URL}/faturas/relatorio-relacionamento-planilha?empresa=${empresa}&ano=${filter.ano}&mes=${filter.mes}`;

    try {
      const response = await fetch(path, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token.replaceAll('"', ""),
        },
      });

      const blob = await response.blob();

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "relatorio.xlsx";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      toast.error(error);
    } finally {
      setIsLoad(false);
    }
  }

  useEffect(() => {
    getRelacionamento({ ...filter, page: numberPage });
  }, [empresa, numberPage]);

  return {
    isMobile,
    pagination,
    setNumberPage,
    faturasRelacionadas,
    v4,
    filter,
    setFilter,
    yearOptions,
    mothOptions,
    handleFilter,
    getDoc,
  };
};
