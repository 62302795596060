import { LayoutTemplate } from "../LayoutTemplate";
import * as S from "./styles";
import { InputDate } from "../../Atoms/Inputs/InputDate";
import { Button } from "../../Atoms/Button";
import { BarChart } from "@mui/x-charts/BarChart";
import { LineChart } from "@mui/x-charts";
import { formatMoneyBRL, maskMoney } from "../../../utils/masks";
import { SimpleSelect } from "../../Atoms/Selects/SimpleSelect";
import { ISelectOptions } from "../../../types/inputs";
import { useFinancialProductionReport } from "./useFinancialProductionReport";
import { v4 } from "uuid";

export const FinancialProductionReportTemplate = () => {
  const {
    control,
    handleSubmit,
    Controller,
    handleFilter,
    dataFinanceira,
    axisLinear,
    dataLinear,
    CidadeOptions,
    GraphColors,
  } = useFinancialProductionReport();

  return (
    <LayoutTemplate title="Relatório de Produção Financeira">
      <S.FormDateFilter onSubmit={handleSubmit(handleFilter)}>
        <div>
          <S.Label>Data Inicial</S.Label>
          <Controller
            control={control}
            name="dataIncio"
            render={({ field: { onChange, value } }) => (
              <InputDate
                required
                showIcon
                selected={value}
                placeholderText="___/___/___"
                onChange={onChange}
              />
            )}
          />
        </div>
        <div>
          <S.Label>Data Final</S.Label>
          <Controller
            control={control}
            name="dataFim"
            render={({ field: { onChange, value } }) => (
              <InputDate
                required
                showIcon
                maxDate={new Date()}
                selected={value}
                placeholderText="___/___/___"
                onChange={onChange}
              />
            )}
          />
        </div>
        <div>
          <S.Label>Empresa</S.Label>
          <Controller
            control={control}
            name="empresa"
            render={({ field: { value, onChange } }) => (
              <SimpleSelect
                options={CidadeOptions}
                isClearable
                value={CidadeOptions.find((i) => i.value === value) || null}
                onChange={(e: ISelectOptions) => onChange(e?.value)}
              />
            )}
          />
        </div>
        <div>
          <S.Label></S.Label>
          <Button>Filtrar</Button>
        </div>
      </S.FormDateFilter>
      <S.WrapperListCards>
        <S.ListCards>
          <S.Card>
            <p>Ticket Médio</p>
            <span>
              {formatMoneyBRL(dataFinanceira?.ticketMedio) || "R$ 0,00"}
            </span>
          </S.Card>
          <S.Card>
            <p>Qtd. R$ 152,00</p>
            <span>
              {dataFinanceira?.qtd152 | 0}
            </span>
          </S.Card>
          <S.Card>
            <p>Qtd. R$ 190,00</p>
            <span>
              {dataFinanceira?.qtd190 | 0}
            </span>
          </S.Card>
          <S.Card>
            <p>Primeiro Emplacamento</p>
            <span>{dataFinanceira?.qtdPrimeiroEmplacamento | 0}</span>
          </S.Card>
          <S.Card>
            <p>Transferência</p>
            <span>{dataFinanceira?.qtdTransferencia | 0}</span>
          </S.Card>
          <S.Card>
            <p>Total Loja</p>
            <span>{dataFinanceira?.totalLoja | 0}</span>
          </S.Card>
          <S.Card>
            <p>Total Móvel</p>
            <span>{dataFinanceira?.totalMovel | 0}</span>
          </S.Card>
          <S.Card>
            <p>Total Itinerante</p>
            <span>{dataFinanceira?.totalItinerante | 0}</span>
          </S.Card>
          <S.Card className="active">
            <p>Total Vistorias</p>
            <span>{dataFinanceira?.totalVistorias | 0}</span>
          </S.Card>
          <S.Card className="active">
            <p>Faturamento</p>
            <span>{formatMoneyBRL(dataFinanceira?.faturamentoTotal) || "R$ 0,00"}</span>
          </S.Card>
        </S.ListCards>
      </S.WrapperListCards>
      <S.FlexWrapperGraficos>
        <S.CardVistoria>
          <S.TitleCard>Vistorias (%)</S.TitleCard>
          <BarChart
            margin={{
              bottom: 70,
            }}
            height={340}
            xAxis={[{ scaleType: "band", data: ["Lojas", "Deliverys", "Itinerantes"] }]}
            slotProps={{
              legend: {
                position: {
                  vertical: "bottom",
                  horizontal: "right",
                },

                labelStyle: { fontSize: 12 },
                itemMarkWidth: 10,
                itemMarkHeight: 10,
                markGap: 5,
                itemGap: 10,
              },
            }}
            series={
              dataFinanceira?.empresas?.map((item) => ({
                data: [item.qtdLoja, item.qtdDelivery, item.qtdItinerante],
                label: item?.empresa,
                color: GraphColors[item?.empresa],
                valueFormatter: (value) => `${value} %`,
              })) || []
            }
            skipAnimation={true}
          />
        </S.CardVistoria>
        <S.CardProducao>
          <S.TitleCard>FATURAMENTO DIÁRIO</S.TitleCard>

          <LineChart
            height={340}
            margin={{
              bottom: 70,
            }}
            series={dataLinear || []}
            xAxis={[{ scaleType: "band", data: axisLinear || [] }]}
            slotProps={{
              legend: {
                position: {
                  vertical: "bottom",
                  horizontal: "right",
                },
                labelStyle: { fontSize: 12 },
                itemMarkWidth: 10,
                itemMarkHeight: 10,
                markGap: 5,
                itemGap: 10,
              },
            }}
          />
        </S.CardProducao>
      </S.FlexWrapperGraficos>
      <S.ExportButton>
        <img src={"/assets/svg/export-icon.svg"} alt="icone exportacao" />{" "}
        Exportar
      </S.ExportButton>

      <S.Table>
        <S.TableHead>
          <h4>Empresa</h4>
          <h4>Unidade</h4>
          <h4 className="center">Loja</h4>
          <h4 className="center">%</h4>
          <h4 className="center">Móvel</h4>
          <h4 className="center">%</h4>
          <h4 className="center">Itinerante</h4>
          <h4 className="center">%</h4>
          <h4 className="center">Ticket Médio</h4>
          <h4 className="center">Total</h4>
        </S.TableHead>
        {dataFinanceira?.lojas?.map((item) => (
          <S.TableRow key={v4()}>
            <p>{item?.empresa || "---"}</p>
            <p>{item?.loja || "---"}</p>
            <p className="center">{item?.qtdLoja || 0}</p>
            <p className="center">{item?.perLoja || 0} %</p>
            <p className="center">{item?.qtdMovel || 0}</p>
            <p className="center">{item?.perMovel || 0} %</p>
            <p className="center">{item?.qtdItinerante || 0}</p>
            <p className="center">{item?.perItinerante || 0} %</p>
            <p className="center">{maskMoney(item.ticketMedio) || 0}</p>
            <p className="center">{item.total || 0}</p>
          </S.TableRow>
        ))}
      </S.Table>
    </LayoutTemplate>
  );
};
