import styled from "styled-components";

export const FormReconciliationReport = styled.form`
  width: 100%;
  max-width: 500px;
  display: grid;
  grid-template-columns: 1fr 1fr 0.2fr;
  gap: 16px;
  align-items: end;
  margin-bottom: 32px;
`;

export const ButtonExport = styled.button`
  all: unset;
  cursor: pointer;
  padding: 6px;
  display: flex;
  gap: 6px;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;

  color: #1d1d1b;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
`;

export const Table = styled.div`
  margin-bottom: 2rem;

  > div + div {
    margin-top: 1.25rem;
  }
`;

export const TableHeader = styled.div`
  border-radius: 0.25rem;
  background: #1d1d1b;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.2);
  display: grid;
  grid-template-columns: 1fr 0.6fr 0.7fr 1fr 0.5fr 0.5fr 0.4fr;
  align-items: center;
  padding: 4px 1rem;
  height: 3.5625rem;

  h3 {
    color: #fff;
    font-family: "Noto Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem;
    letter-spacing: 0.03rem;
  }
`;

export const TableItem = styled.div`
  border-radius: 0.25rem;
  background: #f6f6f6;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.2);
  align-items: center;
  padding: 4px 1rem;
  display: grid;
  height: 3.5625rem;
  grid-template-columns: 1fr 0.6fr 0.7fr 1fr 0.5fr 0.5fr 0.4fr;

  p {
    color: #a3a3a3;
    font-family: "Noto Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
    letter-spacing: 0.03rem;
  }
`;
