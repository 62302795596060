import dayjs from "dayjs";
import { ISelectOptions } from "../../../types/inputs";
import { Button } from "../../Atoms/Button";
import { Pagination } from "../../Atoms/Pagination";
import { SimpleSelect } from "../../Atoms/Selects/SimpleSelect";
import { LayoutTemplate } from "../LayoutTemplate";
import * as S from "./styles";
import { useBankReconciliationReport } from "./useBankReconciliationReport";
import { formatDate } from "../../../utils/dateTransform";
import { textTruncate } from "../../../utils/textTruncate";

export const BankReconciliationReportTemplate = () => {
  const {
    isMobile,
    pagination,
    setNumberPage,
    faturasRelacionadas,
    v4,
    filter,
    setFilter,
    yearOptions,
    mothOptions,
    getDoc,
    handleFilter,
  } = useBankReconciliationReport();

  return (
    <LayoutTemplate title="Conciliação Bancária">
      <div>
        <S.FormReconciliationReport onSubmit={handleFilter}>
          <div>
            <label>Mês</label>
            <SimpleSelect
              options={mothOptions}
              value={mothOptions.find((i) => +i.value === filter?.mes) || null}
              onChange={(e: ISelectOptions) => {
                e && setFilter((prev) => ({ ...prev, mes: +e.value }));
                console.log(e);
              }}
            />
          </div>

          <div>
            <label>Ano</label>
            <SimpleSelect
              value={yearOptions.find((i) => i.value === filter.ano) || null}
              onChange={(e: ISelectOptions) =>
                e && setFilter((prev) => ({ ...prev, ano: e.value }))
              }
              options={yearOptions}
            />
          </div>

          <div>
            <Button>Filtrar</Button>
          </div>
        </S.FormReconciliationReport>

        <div>
          <S.ButtonExport onClick={getDoc}>
            <img src="/assets/svg/icon-export-down.svg" alt="icon download" />
            Exportar
          </S.ButtonExport>
        </div>

        <div>
          <S.Table>
            <S.TableHeader>
              <h3>ID da Fatura</h3>
              <h3>Data de Criação</h3>
              <h3>Data de Pagamento</h3>
              <h3>Nome do Cliente</h3>
              <h3>Placa/Chassi</h3>
              <h3>Status Mapa</h3>
              <h3>Status Iugu</h3>
            </S.TableHeader>

            {faturasRelacionadas?.length > 0 &&
              faturasRelacionadas?.map((i) => (
                <S.TableItem key={v4()}>
                  <p title={i?.id}>
                    {textTruncate({ limit: 20, text: i?.id })}
                  </p>
                  <p>{formatDate(i?.dataCriacao)}</p>
                  <p>{formatDate(i?.dataPagamento)}</p>
                  <p>{i?.clienteNome}</p>
                  <p>{i?.placaChassi}</p>
                  <p>{i?.statusMapa}</p>
                  <p>{i?.statusIugu}</p>
                </S.TableItem>
              ))}

            {faturasRelacionadas?.length > 0 && (
              <Pagination
                maxPageNumbersDisplayed={isMobile ? 3 : 10}
                key={`${Math.random()} - ${pagination}`}
                totalPage={pagination.totalPage}
                totalRegister={pagination.totalPage}
                actualPage={pagination.actualPage}
                setNumberPage={setNumberPage}
              />
            )}
          </S.Table>
        </div>
      </div>
    </LayoutTemplate>
  );
};
