import { useEffect, useState } from "react";
import { Gerenciamento } from "../../../services/Gerenciamento";
import {
  reverseToBrDate,
  reverseToIsoDate,
} from "../../../utils/dateTransform";

import { toast } from "react-toastify";
import { useContextSite } from "../../../context/Context";
import { z } from "zod";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { GraphColors } from "../../../utils/graphCorlors";
import {
  IGerenciamentoDTO,
  IProdFinanceiraDTO,
  IProducaoFinanceiraProps,
} from "../../../types/gerenciamento";
import { formatMoneyBRL } from "../../../utils/masks";

type FilterDateTypes = {
  dataIncio?: Date;
  dataFim?: Date;
  empresa?: string;
};

const schema = z.object({
  dataIncio: z.date().nullable().optional(),
  dataFim: z.date().nullable().optional(),
  empresa: z.string().min(1).optional().or(z.literal("")),
});

const CidadeOptions = [
  {
    label: "Log",
    value: "Log",
  },
  {
    label: "Starcheck",
    value: "Starcheck",
  },
  {
    label: "Tokyo",
    value: "Tokyo",
  },
  {
    label: "Velox",
    value: "Velox",
  },
];

export const useFinancialProductionReport = () => {
  const { setIsLoad } = useContextSite();
  const [dataLinear, setDataLinear] = useState([]);
  const [axisLinear, setAxisLinear] = useState([]);

  const [dataFinanceira, setDataFinanceira] = useState<IGerenciamentoDTO>(
    {} as IGerenciamentoDTO
  );

  const { control, handleSubmit } = useForm<FilterDateTypes>({
    defaultValues: {
      dataFim: new Date(),
      dataIncio: new Date(),
      empresa: "",
    },
    mode: "all",
    resolver: zodResolver(schema),
  });

  function getFinanceiro(filter: IProducaoFinanceiraProps) {
    setIsLoad(true);
    Gerenciamento.producaoFinanceira(filter)
      .then(({ data }) => {
        formatLineChartData(data.producaoDiaria);
        setDataFinanceira(data);
      })
      .catch(
        ({
          response: {
            data: { mensagem },
          },
        }) => toast.error(mensagem)
      )
      .finally(() => setIsLoad(false));
  }

  const formatLineChartData = (data) => {
    const dates = [];
    const series = data.map((empresa) => {
      empresa.producao.forEach((producao) => {
        if (!dates.includes(producao.data)) {
          dates.push(producao.data);
        }
      });
      return {
        label: empresa.empresa,
        data: empresa.producao.map((producao) => producao.faturamento /100),
        color: GraphColors[empresa?.empresa],
        valueFormatter: (value) => formatMoneyBRL(value),
      };
    });

    setDataLinear(series);
    setAxisLinear(dates.map(reverseToBrDate));

    return { dates: dates.map(reverseToBrDate), series };
  };

  function handleFilter(data: FilterDateTypes) {
    setIsLoad(true);

    const FILTER = {
      dataInicio: reverseToIsoDate(data?.dataIncio?.toLocaleDateString()),
      dataFim: reverseToIsoDate(data?.dataFim?.toLocaleDateString()),
      empresa: data?.empresa,
    };

    getFinanceiro(FILTER);
  }

  useEffect(() => {
    const dataInicio = reverseToIsoDate(new Date()?.toLocaleDateString());
    const dataFim = reverseToIsoDate(new Date()?.toLocaleDateString());

    getFinanceiro({
      dataInicio,
      dataFim,
    });
  }, []);

  return {
    handleFilter,
    dataFinanceira,
    dataLinear,
    axisLinear,
    Controller,
    control,
    handleSubmit,
    CidadeOptions,
    GraphColors,
  };
};
