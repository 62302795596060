import { ISelectOptions } from "../../../types/inputs";
import { Button } from "../../Atoms/Button";
import { SimpleSelect } from "../../Atoms/Selects/SimpleSelect";
import { LayoutTemplate } from "../LayoutTemplate";

import * as S from "./styles";
import { useCustomerSatisfaction } from "./useCustomerSatisfaction";

export const CustomerSatisfactionTemplate = () => {
  const {
    mothOptions,
    yearOptions,
    unidadesOptions,
    filter,
    handleFilter,
    pesquisa,
    setFilter,
  } = useCustomerSatisfaction();
  return (
    <LayoutTemplate title="Satisfação do Cliente">
      <S.Container>
        <S.FormFilterCustomerSatisfaction onSubmit={handleFilter}>
          <div>
            <label>Mês</label>
            <SimpleSelect
              options={mothOptions}
              value={mothOptions.find((i) => +i.value === filter?.mes) || null}
              onChange={(e: ISelectOptions) =>
                e && setFilter((prev) => ({ ...prev, mes: +e.value }))
              }
            />
          </div>
          <div>
            <label>Ano</label>
            <SimpleSelect
              value={yearOptions.find((i) => i.value === filter.ano) || null}
              onChange={(e: ISelectOptions) =>
                e && setFilter((prev) => ({ ...prev, ano: e.value }))
              }
              options={yearOptions}
            />
          </div>
          <div>
            <label>Unidade</label>
            <SimpleSelect isClearable options={unidadesOptions} />
          </div>

          <div>
            <Button>Filtrar</Button>
          </div>
        </S.FormFilterCustomerSatisfaction>

        <S.Wrapper>
          <h3>Quantidade De respostas realizadas dentro do mês</h3>

          <S.WrapperCards>
            <S.Cards>
              <p>Particular</p>
              <span>{pesquisa[0]?.quantidadeParticular || 0}</span>
            </S.Cards>

            <S.Cards>
              <p>Concessionária</p>
              <span>{pesquisa[0]?.quantidadeConcessionaria || 0}</span>
            </S.Cards>

            <S.Cards>
              <p>Despachante</p>
              <span>{pesquisa[0]?.quantidadeDespachante || 0}</span>
            </S.Cards>

            <S.Cards className="active">
              <p>Total</p>
              <span>{pesquisa[0]?.quantidadeTotal || 0}</span>
            </S.Cards>
          </S.WrapperCards>

          <h3>Notas</h3>

          <S.WrapperCards className="wrap">
            <S.Cards className="simple">
              <p>Particular</p>
              <span>
                <img
                  src="/assets/svg/icon-star-outline.svg"
                  alt="icone estrela"
                />
                <span>{pesquisa[0]?.notaAtendimentoCliente || 0}</span>
              </span>
            </S.Cards>

            <S.Cards className="simple">
              <p>Qualidade do Serviço Prestado</p>
              <span>
                <img
                  src="/assets/svg/icon-star-outline.svg"
                  alt="icone estrela"
                />
                <span>{pesquisa[0]?.notaQualidadeServico || 0}</span>
              </span>
            </S.Cards>

            <S.Cards className="simple">
              <p>Geral de Satisfação do Cliente</p>
              <span>
                <img
                  src="/assets/svg/icon-star-outline.svg"
                  alt="icone estrela"
                />
                <span>{pesquisa[0]?.notaGeral || 0}</span>
              </span>
            </S.Cards>

            <S.Cards className="simple">
              <p>Agendamentos Realizados no Horário</p>
              <span>
                <img
                  src="/assets/svg/icon-star-outline.svg"
                  alt="icone estrela"
                />
                <span>{pesquisa[0]?.porcentagemAgendamentosHorario || 0}</span>
              </span>
            </S.Cards>

            <S.Cards className="simple">
              <p>Pessoas que Recomendariam a Empresa</p>
              <span>
                <img
                  src="/assets/svg/icon-star-outline.svg"
                  alt="icone estrela"
                />
                <span>{pesquisa[0]?.porcentagemRecomendacao || 0}</span>
              </span>
            </S.Cards>
          </S.WrapperCards>
        </S.Wrapper>
      </S.Container>
    </LayoutTemplate>
  );
};
